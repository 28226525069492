// @import 'custom_bootstrap_vars';
// @import '~bootstrap/scss/bootstrap';
@import './custom_choices';

// project specific CSS goes here

///////////////
// Variables //
///////////////

// Alert colors

$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

////////////
// Alerts //
////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error


@font-face {
  font-family: 'Mark Pro';
  src: url('../fonts/Mark-Pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Mark Pro', sans-serif;
}


.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .btn-primary {
    @apply flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 p-3.5 rounded-tl-[10px] rounded-tr-[32px] rounded-bl-[32px] rounded-br-[10px] bg-accent text-white text-left font-bold text-base;
    box-shadow: 2px 4px 8px 0 rgba(61, 61, 61, 0.1);
  }

  .btn-follow {
    @apply flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 px-3.5 py-1.5 rounded-tl-[5px] rounded-tr-[16px] rounded-bl-[16px] rounded-br-[5px] bg-accent text-white text-left font-bold text-base;
    box-shadow: 2px 4px 8px 0 rgba(61, 61, 61, 0.1);
  }

  .btn-unfollow {
    @apply flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 px-3.5 py-1.5 rounded-tl-[5px] rounded-tr-[16px] rounded-bl-[16px] rounded-br-[5px] bg-neutral-200 text-neutral-700 text-left font-bold text-base;
    box-shadow: 2px 4px 8px 0 rgba(61, 61, 61, 0.1);
  }

  .btn-outline {
    @apply text-accent hover:text-white border border-accent hover:bg-accent focus:ring-4 focus:outline-none focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2;
  }

  .btn-primary-outline {
    @apply flex justify-center items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-2.5 p-3.5 rounded-tl-[10px] rounded-tr-[32px] rounded-bl-[32px] rounded-br-[10px] text-accent font-bold text-base;
    background-color: transparent;
    border: 2px solid var(--accent-color);
    box-shadow: 2px 4px 8px 0 rgba(61, 61, 61, 0.1);
  }

  .btn-primary-sm {
    @apply gap-1 p-2 rounded-tl-[5px] rounded-tr-[16px] rounded-bl-[16px] rounded-br-[5px] bg-accent text-white text-sm font-bold;
    box-shadow: 2px 4px 8px 0 rgba(61, 61, 61, 0.1);
  }

  .size-range-button-style {
    @apply rounded-xl p-2 text-neutral-800 border border-neutral-300 text-sm font-medium w-20;
  }

  .size-range-button-style.active {
    @apply bg-amber-100 border-amber-400;
  }

  .size-range-label {
    @apply block font-semibold text-center text-neutral-800 text-sm;
  }

  .size-range-value {
    @apply text-neutral-600 text-xs;
  }

  // .range-button {
  //   @apply bg-gray-100 text-gray-800 text-sm me-1 md:me-2 px-1 md:px-2.5 py-0.5 rounded
  // }
  .range-button {
      @apply rounded-lg p-1 px-2 text-neutral-800 border border-neutral-300 text-sm me-1 md:me-2;
    }

  .range-button.active, .range-button:checked {
    @apply bg-amber-100 text-slate-900 border border-amber-300;
  }

  .electric-range-button-style {
    @apply flex-grow rounded-xl p-2 text-neutral-800 border border-neutral-300 text-sm font-medium flex items-center justify-center gap-2;
  }

  .electric-range-button-style.active {
    @apply bg-amber-100 border-amber-400;
  }

  .electric-range-button-style:checked {
    @apply bg-amber-100 border-amber-400;
  }

  input[type="radio"]:checked+.electric-range-button-style {
    @apply bg-amber-100 border-amber-400;
  }

  .range-reset-button {
    @apply text-neutral-800 text-sm me-1 md:me-2 p-1 px-2 md:px-2.5 rounded flex items-center gap-x-1
  }

  .container {
    @apply mx-auto max-w-[1440px] px-4;
  }

  .link {
    @apply text-accent underline text-sm font-normal;
  }

  .subtitle {
    @apply text-xl text-neutral-700 font-bold;
  }

  .title {
    @apply text-3xl text-neutral-800 font-bold;
  }

  .form-label {
    @apply block text-sm font-medium text-neutral-700;
  }

  .input-style {
    @apply w-full focus:ring-amber-200 focus:border-amber-200 flex-grow outline-1 border border-neutral-300 p-2 px-4 rounded-full text-neutral-800
  }
  .multiselect-input-style {
    @apply w-full focus:ring-amber-200 focus:border-amber-200 flex-grow outline-1 border border-neutral-300 p-2 px-4 rounded-2xl text-neutral-800
  }
  .alert-error {
    @apply bg-red-300 text-red-800 text-sm font-bold p-5;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.filters-collapsible-header {
  display: flex;
  cursor: pointer;
  list-style-type: none;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: #f5f5f5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 500;
}

summary::-webkit-details-marker {
  display: none;
}

summary {
  list-style: none;
}

@keyframes loading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.animate-load {
  animation: loading 2s linear infinite;
}

#loadingIndicator.htmx-request {
  opacity: 1;
}